import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "has-deactivate-button": false, "title": "Edit Product Standard" } }, [_c("text-input", { key: "code", attrs: { "form-item": "", "label": "Item Code", "disabled": true, "span": 8 } }), _c("text-input", { key: "description", attrs: { "form-item": "", "label": "Description", "disabled": true, "span": 16 } }), _c("number-input", { key: "netWeight", attrs: { "form-item": "", "label": "Net Weight", "disabled": true, "suffix": "KG", "span": 12 } }), _c("number-input", { key: "standardCPM", attrs: { "form-item": "", "label": "Standard Speed", "suffix": "UPM", "span": 12, "rules": "required", "type": "decimal" } }), _c("number-input", { key: "bottleneckCPM", attrs: { "form-item": "", "label": "Bottleneck", "suffix": "UPM", "span": 12, "type": "decimal", "rules": "required" } }), _c("number-input", { key: "unitUOM", attrs: { "disabled": true, "form-item": "", "label": "Unit / Pack", "span": 12 } }), _c("select-input", { key: "productionLineID", attrs: { "form-item": "", "label": "Production Line", "reference": "line-performance.common.local-production-lines", "custom-query": "facilityId=" + _vm.facility.value + "&areaId=" + _vm.area.value, "only-custom-query": true, "source": "id", "source-label": "code", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditProductStandard",
  inject: ["facility", "area"]
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditProductStandard = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-product-standard" }, [_c("resource", { attrs: { "name": "line-performance.common.local-production-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.product-masters", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/product-standards" } }, [_c("edit-product-standard")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditProductStandard
  },
  data() {
    return {
      apiUrl,
      EditProductStandard
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
